#bg{
  width: 100%;
  height: 120px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#header{
  width: 100%;
  font-size: 11px;
  font-weight: 550;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #ececfd;
}
#body{
  height: 100%;
  width: 100%;
  display: flex;
}
.linears{
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.linears p{
  font-size: 8px;
  color: gray;
}
.btnShape{
  min-width: 100px;
  padding: 10px;
  border-radius: 80px;
  margin: 6px;
  background-color: #ececfd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
}
#btn {
  min-width: 90px;
  position: relative;
  background: linear-gradient(to bottom, #d62976, #f40057);
  color: #ffffff;
  /* border: 1px solid #7f0059a8; */
  padding: 6px;
  border-radius: 8px; 
  font-size: 16px;
  box-shadow: 
    inset 0px -2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#btn s{
  font-size: 12px;
}
/* Glossy Reflection Effect */
#btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}