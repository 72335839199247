#bg{
  width: 100%;
  height: 180px;
  border-radius: 15px;
  padding: 15px;
  padding-top: 15px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#header{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
#header h1{
  font-size: 14px;
  font-weight: 525;
}
#header p{
  font-size: 11px;
  color: #000a2d;
  font-weight: 500;
}
#body{
  width: 100%;
  height: 100px;
  flex-grow: 1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
}
.linear{
  width: 25%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(to bottom, #d62976, #f40057);
  box-shadow: 0px 3px 0px #7f0059a8 ;
}
.rewards{
  width: 40px;
  height: 40px;
  background: linear-gradient(180deg, transparent, #0f001954);
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 525;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.linear p{
  font-size: 12px;
  color: white;
  font-weight: 500;
  padding: 3px;
}
.btns{
  padding: 3px;
  min-width: 55px;
  border: none;
  color: black;
  background-color: silver;
  border-radius: 20px;
  font-size: 10px;
  margin: 5px;
  box-shadow: 0px 3px 0px #e2009f61 ;
  position: relative;
  overflow: hidden;
}
.btns::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}