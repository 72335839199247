#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  position: relative;
  padding-top: 65px;
}
#header{
  width: 100%;
  height: 65px;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); */
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7px;
  background-color: white;
  position: absolute;
  top: 0;
}
#header p{
  font-size: 18px;
  font-weight: 500;
}
#header i{
  padding: 8px;
  font-size: 15px;
}
.notificationDiv{
  width: 100%;
  display: flex;
  justify-content: center;
}
.notifications{
  width: 92%;
  padding: 15px;
  display: flex;
  font-size: 14px;
  margin-top: 12px;
  background-color: #ffffff;
  border-radius: 8px;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); */
}
.notificationImg{
  width: 50px;
  height: 50px;
  margin-right: 8px;
}
.notificationDiv span{
  color: #41363b;
  margin-left: 4px;
  font-size: 11px;
}
.optionBtn{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  font-size: 14px;
  color: #555;
}