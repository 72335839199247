/* CSS code for E-Zexa Store (EStore) */
#bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#box{
  width: 90%;
  height: 80%;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
}
#exitBtn{
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: red;
  border: none;
  position: relative;
  overflow: hidden;
  color: white;
  font-size: 18px;
  position: absolute;
  top:  -8px;
  right: -8px;
}
#exitBtn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
#header{
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #555;
}

#body{
  width: 100%;
  flex-grow: 1;
}

#footer{
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #a3003f;
  font-weight: 500;
}

