#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  min-height: 50%;
  position: relative;
  padding-top: 60px;
  overflow-y: scroll;
  scrollbar-width: none;
}
#root::-webkit-scrollbar{
  display: none;
}
#header{
  width: 100%;
  height: 65px;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); */
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
}
#header p{
  font-size: 18px;
  font-weight: 500;
}
#header i{
  padding: 8px;
  font-size: 15px;
}
#soundControlBtn{
  width: 40px;
  height: 20px;
  font-size: 10px;
  background: linear-gradient(to bottom, #f4005730, #e2009f4d);
  color: #f40057;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  overflow: hidden;
}
/* Glossy Reflection Effect */
#soundControlBtn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) rotate(-3deg);
}
#soundControlBtn i{
  font-size: 12px;
}
#tabsBg{
  width: 100%;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); */
  background-color: white;
  display: flex;
}
.enableTab{
  border-bottom: 2px solid #f40057;
}
.tabs{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  color: #f40057;
  font-weight: 500;
  padding: 10px;
  padding-top: 3px;
}

/* Glossy Reflection Effect */
.glowEffect{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(to bottom, #d62976, #f40057);
  color: #ffffff;
  border-radius: 60px; 
  font-size: 16px;
  box-shadow: 
    inset 0px -2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.glowEffect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
#body{
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
}
#banner{
  width: 100%;
  /* background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); */
  aspect-ratio: 16/6;
  /* background-image: url("../../assets/abstractBg1.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 12px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
}
#topText{
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  color: #555;
}
.bannerDivs{
  width: 21%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.top3PlyersProfileContainer{
  background: linear-gradient(180deg, #f40057, #a3003f);
  border-radius: 50%;
  padding: 2px;
  margin: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerDivs span{
  font-size: 10px;
  font-weight: 500;
}
.bannerDivs p{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(90deg, #d62976, #962fbf, #4f5bd5);
  color: white;
  font-size: 10px;
  position: absolute;
  bottom: -8px;
  overflow: hidden;
}
.bannerDivs p::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
#firstRankDiv{
  width: 40%;
}
#firstRankDiv span{
  font-size: 12px;
}
#secondRankProfilePicture{
  margin: 0;
  width: 70px;
  height: 70px;
}
#firstRankProfilePicture{
  margin: 0;
  width: 100px;
  height: 100px;
}
#thirdRankProfilePicture{
  margin: 0;
  width: 60px;
  height: 60px;
}
#listview{
  width: 92%;
  min-height: 100%;
  padding: 15px;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.lists{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
}
.listProfilePicture{
  width: 45px;
  height: 45px;
  margin: 8px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.listProfilePicture p{
  width: 20px;
  height: 20px;
  color: white;
  background-color: #f40057;
  /* background: linear-gradient(90deg, #d62976, #962fbf, #4f5bd5); */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7px!important;
  border-radius: 50%;
  position: absolute;
  right: -6px;
  bottom: -6px;
  margin-bottom: 3px;
  margin-right: 7px;
  font-weight: 550;
  border: 2px solid white;
  overflow: hidden;
}
.listProfilePicture p::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
.listsRank{
  font-size: 20px;
  color: #343434;
  font-weight: 500;
  width: 30px;
  text-align: center;
}
.userName{
  font-weight: 450;
  color: #343434;
  font-size: 14px;
}
.hl{
  width: 92%;
  height: 0.5px;
  background-color: #f4005730;
}
.rankImg{
  width: 40px;
  height: 40px;
}
.space{
  width: 10px;
  height: 100%;
  flex-grow: 1;
}
.rankDiv{
  display: flex;
  flex-direction: column;
  font-size: 10px;
  justify-content: center;
  align-items: center;
}
.rankDiv div{
  display: flex;
}
#SeasonRewards{
  width: 100%;
  padding: 15px;
}
/* #topBox{
  width: 92%;
  aspect-ratio: 16/10;
  background-image: url('../../assets/leaderBoardStageBg2.jpeg');
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat ;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
#topBox div{
  width: 30.55%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#topBox img{
  width: 60px;
  height: 60px;
}
#secondStage{
  padding-bottom: 2.5%;
}
#firstStage{
  padding-bottom: 10%;
}
#thirdStage{
  padding-top: 5%;
} */