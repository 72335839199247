@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
#bg{
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
}
#bg::-webkit-scrollbar{
  display: none;
}
#toolbar{
  width: 100%;
  height: 65px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
#menuBtn{
  font-size: 20px;
  padding: 8px;
  margin-left: 8px;
}
#logo{
   height: 60%;
   padding-top: 2px;
   padding-bottom: 2px;
}
.linear{
  display: flex;
  align-items: center;
  height: 100%;
}
#dailyRewards{
  background-color: #f4005730;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  margin-right: 8px;
  color: #f40057;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
#wallet{
  padding: 12px;
  background-color: #f4005730;
  color: #f40057;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  overflow: visible;
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
#wallet i{
  padding-right: 8px;
}
#wallet::before, #dailyRewards::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) rotate(-3deg);
}
#rankBadge{
  padding: 3px;
  background-color: #f4005730;
  border-radius: 5px;
  font-weight: 500;
  height: auto;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 8px; */
  position: relative;
  overflow: hidden;
}
#rankBadge::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) rotate(-3deg);
}
#rankBadge img{
  width: 28px;
  height: 28px;
}

#content{
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
}
.storiesBg{
  width: 76px;
  height: 76px;
  border-radius: 50%;
  padding: 3px;
  background: linear-gradient(to bottom, #d62976, #f40057);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  position: relative;
}
.storiesBg div{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #d62976, #962fbf, #4f5bd5);
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  right: 2px;
  overflow: hidden;
}
.storiesBg div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
.stories{
  width: 70px;
  height: 70px;
  border: 4px solid white;
  border-radius: 50%;
  background-color: #FFFFFF;
}
.storiesDiv{
  margin: 10px;
  margin-top: 10px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: grey;
}


.headings{
  width: 100%;
  display: flex;
  padding: 15px;
}
.headings p{
  font-size: 16px;
  font-weight: 500;
}
.headings div{
  width: 100px;
  flex-grow: 1;
}
.headings i{
  font-size: 14px;
  color: blue;
  font-weight: 475;
}


.horizontalScrollBar{
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}
.scroll{
  display: inline-flex;
  padding-left: 5px;
}
.horizontalScrollBar::-webkit-scrollbar{
  display: none;
}


.games{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0 6px 12px;
  font-size: 13px;
  color: grey;
  position: relative;
  scroll-snap-align: start;
}
.games img {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}


.tags{
  height: 20px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 4px;
  right: -6px;
  top: 0;
  /* background: linear-gradient(90deg, #d62976, #962fbf, #4f5bd5); */
  background: linear-gradient(to bottom, #d62976, #f40057);
  color: white;
  font-size: 12px;
  overflow: hidden;
}
.tags::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}

#spacialDiv{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10px;
  padding-top: 15px;
}
.spacialBoxes{
  width: 45%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #e2009f12;
}
.spacialBoxes img{
  width: 100%;
  aspect-ratio: 1/1;
}
.spacialBoxes div{
  width: 100%;
  height: 60px;
  padding: 6px;
}
.spacialBoxes h1{
  font-size: 13px;
  font-weight: 550;
}
.spacialBoxes p{
  font-size: 12px;
}
.spacialBoxes span{
  font-size: 10px;
}
.spacialBoxes i{
  padding-right: 2px;
}


.rowLinears{
  flex: 0 0 92%;
  width: 100%;
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
}
.rowLines{
  width: 100%;
  display: flex;
  padding: 12px;
  padding-bottom: 6px;
}
.rowLines img{
  width: 75px;
  height: 75px;
  border-radius: 10px;
  margin-right: 12px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}
.rowLines div{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.rowLines h1{
  font-size: 17px;
  font-weight: 500;
  padding: 2px;
}
.rowLines p{
  font-size: 13px;
  color: #555;
  padding: 2px;
}
.rowLines span{
  font-size: 13px;
  color: #555;
  padding: 2px;
}