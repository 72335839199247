#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: radial-gradient(circle at center, #2e2e2e, #000000);
}
#header{
  width: 100%;
  height: 60px;
}
#pg{
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#footer{
  width: 100%;
  height: 60px;
}
#board{
  width: 70%;
  aspect-ratio: 3/5.2;
  border-radius: 150px;
  border: 5px solid #d4af37;
  background: radial-gradient(circle at center, #2e2e2e, #000000);
  transform: perspective(400px) rotateX(25deg);
  padding: 15px;
}
#innerBoard{
  width: 100%;
  height: 100%;
  border: 1.5px solid #d4af37;
  border-radius: 150px;
}