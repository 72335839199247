#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: radial-gradient(circle at center, #00145b, #000825);
}
#header{
  width: 100%;
  height: 60px;
  display: flex;
}



#body{
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
#board{
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 15px;
  outline: 3px solid #fff;
  position: relative;
}
#boardBg{
  width: 100%;
  aspect-ratio: 1/1;
}
#prizeDiv{
  width: 10%;
  height: 10%;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 14px;
}

#firstRow{
  width: 100%;
  height: 40%;
  /* border: 1px solid white; */
  display: flex;
}
#secondRow{
  width: 100%;
  height: 20%;
  /* border: 1px solid white; */
  display: flex;
}
#thirdRow{
  width: 100%;
  height: 40%;
  /* border: 1px solid white; */
  display: flex;
}
.Rows1{
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Rows2{
  width: 20%;
  height: 100%;
  display: flex;
}
.Rows3{
  width: 40%;
  height: 100%;
}
.columns{
  width: 33.333333%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.rows{
  width: 100%;
  height: 33.333333%;
  display: flex;
  
}
.columns .tiles{
  width: 100%;
  height: 16.66666%;
  box-shadow: 0 0 0 0.6px #555;
  background-color: #ffffff;
  z-index: 1;
  border-radius: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rows .tiles{
  width: 16.66666%;
  height: 100%;
  box-shadow: 0 0 0 0.6px #555;
  background-color: #ffffff;
  z-index: 1;
  border-radius: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#greenBigBox{
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
}
#yellowBigBox{
  background-color: gold;
  display: flex;
  justify-content: center;
  align-items: center;
}
#blueBigBox{
  background-color: #148aff;
  display: flex;
  justify-content: center;
  align-items: center;
}
#redBigBox{
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scroreBoxes{
  width: 70%;
  height: 70%;
  background-color: #ffffff;
  border-radius: 50%;
}
.greenTiles{
  background-color: green !important;
}
.yellowTiles{
  background-color: gold !important;
}
.redTiles{
  background-color: red !important;
}

.blueTiles{
  background-color: #148aff !important;
}
#homeCenterBox{
 overflow: hidden;
 margin: auto;
 position: relative;
 /* display: flex;
 justify-content: center;
 align-items: center; */
}
#homeCenterBox div{
  height: 100%;
  width: 100%;
  border-left: 100vh solid green;
  border-right: 100vh solid #148aff;
  border-top: 100vh solid yellow;
  border-bottom: 100vh solid red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}






#footer{
  width: 100%;
  height: 60px;
  display: flex;
}