#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0f0f0f;
  overflow-y: scroll;
  scrollbar-width: none;
}
#root::-webkit-scrollbar{
  display: none;
}
#banner{
  width: 100%;
  background-color: #110030;
}
.linear{
  display: flex;
  align-items: center;
}
#header{
  width: 100%;
  height: 100px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
#header p{
  padding: 8px;
  background-color: #ffffff30;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#header img{
  width: 100px;
}
#wallet{
  padding: 12px;
  background: linear-gradient(to bottom, #008b1e, #00ce2c);
  color: #ffffff;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  overflow: visible;
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
#wallet i{
  padding-right: 8px;
}
#wallet::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
#notifier {
  width: 100%;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 15px;
  margin-bottom: 15px;
}
#notifier::-webkit-scrollbar {
  display: none;
}
#scroll {
  display: flex;
  padding-left: 5px;
}
.notifierBoxes {
  background-color: #f0f2f5;
  color: rgba(163,163,163, 1);
  font-size: 14px;
  min-width: 70px;
  height: 30px;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  /* border: 1.5px solid rgba(163,163,163,0.353); */
  padding: 10px;
}
#linear{
  width: 100%;
  min-width: 100%;
  background-color: #ffffff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  flex-grow: 1;
  padding: 15px;
}
#linear h1{
  font-size: 17px; 
  font-weight: 550;
}
.notifierSelect{
  position: relative;
  background: linear-gradient(to bottom, #d62976, #f40057);
  overflow: hidden;
  color: #ffffff;
}
.notifierSelect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}