#bg{
  width: 100%;
  height: 100%;
  background-color: #010221;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
}
#header{
  width: 100%;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 18px;
}
#header i{
  font-size: 20px;
  margin-right: 12px;
}
#body{
  width: 100%;
  height: 100px;
  flex-grow: 1;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#vsImg{
  width: 80px;
}
.linear{
  width: 100%;
  height: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.linear p{
  padding: 6px;
  font-weight: 500;
}
.linear img{
  width: 100px;
  height: 100px;
}
#roomKeyDiv{
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 8px;
  background-color: white;
  color: black;
  font-weight: 500;
}
#roomKeyDiv i{
  padding: 5px !important;
}