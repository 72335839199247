#bg{
  width: 100%;
  flex-flow: 1;
}
.scrollDiv{
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}
.scroll{
  display: inline-flex;
  padding-left: 5px;
}
.scrollDiv::-webkit-scrollbar{
  display: none;
}
.circleBg{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text{
  margin: 5px;
  width: 40px;
  height: 10px;
  background-color: #d8d8d8c3;
  border-radius: 20px;
}
.heading{
  margin: 5px;
  width: 50px;
  height: 12px;
  background-color: #d8d8d8c3;
  border-radius: 20px;
}
.circle{
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #d8d8d8c3;
  margin: 10px;
  margin-top: 10px;
  margin-left: 5px;
}
#spacialDiv{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px;
  padding-bottom: 10px;
  padding-top: 15px;
}
.spacialBoxes{
  width: 100%;
  aspect-ratio: 2.9/1;
  border-radius: 10px;
  overflow: hidden;
  background-color: #d8d8d8c3;
}
.headingDiv{
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.squreBg{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.squre{
  width: 90px;
  height: 90px;
  border-radius: 10px;
  background-color: #d8d8d8c3;
  margin: 10px;
  margin-top: 10px;
  margin-left: 5px;
}