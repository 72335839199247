#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#pages{
  width: 100%;
  height: auto;
  flex-grow: 1;
  overflow: hidden;
  overflow-y: scroll;
}
#pages::-webkit-scrollbar{
  display: none;
}
#navbar{
  width: 100%;
  height: 55px;
}