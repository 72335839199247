#bg{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}
#bg::-webkit-scrollbar{
  display: none;
}
#bg2{
  width: 100%;
  background-image: url('../../assets/bg2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 65px;
}
#toolbar{
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
}
#toolbar i{
  font-size: 15px;
  color: white;
  padding: 12px;
}
#toolbar span{
  font-size: 20px;
  font-weight: 500;
  color: white;
}
#logo{
   height: 65%;
   padding-top: 2px;
   padding-bottom: 2px;
}
.linear{
  display: flex;
  align-items: center;
  height: 100%;
}
#wallet{
  padding: 12px;
  background-color: #ffffff6c;
  color: #ffffff;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  overflow: visible;
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
#wallet i{
  padding-right: 8px;
  padding-left: 4px;
}
#wallet::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) rotate(-3deg);
}
#rankBadge{
  padding: 3px;
  background-color:  #ffffff6c;
  border-radius: 5px;
  font-weight: 500;
  height: auto;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  position: relative;
  overflow: hidden;
}
#rankBadge::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) rotate(-3deg);
}
#rankBadge img{
  width: 28px;
  height: 28px;
}
#ranksDiv{
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 4%;
}
#ranksDiv img{
  width: 35%;
  aspect-ratio: 1/1;
}
#p1{
  color: #ffffff6c;
  font-weight: 500;
  padding-bottom: 5px;
}
#btnsDiv{
  margin-top: 25%;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
}
#btnsDiv button{
  width: 45%;
  height: 40px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 15px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
#btn1{
  position: relative;
  background: linear-gradient(to bottom, #f4005730, #e2009f4d);
  color: #f40057;
  /* border: 1px solid #7f0059a8; */
  padding: 6px;
  border-radius: 8px; 
  font-size: 16px;
  overflow: hidden;
}
#btn2 {
  position: relative;
  background: linear-gradient(to bottom, #d62976, #f40057);
  color: #ffffff;
  /* border: 1px solid #7f0059a8; */
  padding: 6px;
  border-radius: 8px; 
  font-size: 16px;
  overflow: hidden;
}

/* Glossy Reflection Effect */
#btnsDiv button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
.hl{
  width: 100%;
  height: 12px;
  background-color: #bfbfbf54;
  margin-top: 25px;
}
#ranksViewDiv{
  width: 100%;
  padding: 15px;
  padding-top: 12px;
  padding-bottom: 0;
  font-size: 14px;
  font-weight: 525;
}
#ranksProgressLinear{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
#ranksProgressBg{
  width: 61%;
  height: 8px;
  background-color: lightgray;
  position: absolute;
  overflow: hidden;
}
#ranksProgressBar{
  width: 0;
  height: 100%;
  background: linear-gradient(to bottom, #d62976, #f40057);
}
#ranksView{
  width: 100%;
  aspect-ratio: 10/2.5;
  background-image: url('../../assets/rankStepProgress.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 6px;
  z-index: 1;
}