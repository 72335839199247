#bg{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
#bg2{
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}
.hl{
  width: 100%;
  height: 10px;
  background-color: #f0f2f5;
  margin-top: 10px;
  margin-bottom: 10px;
}
#header{
  width: 100%;
  padding: 5px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#logo{
  font-size: 16px;
  font-weight: 520;
}
#headerBtns{
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 10px; */
}
#headerLinear{
  display: flex;
}
#header i{
  width: 28px;
  height: 28px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}
.wallet{
  padding: 12px;
  background-color: #f4005730;
  color: #f40057;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  overflow: visible;
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
.wallet i{
  padding-right: 8px;
}
.wallet::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) rotate(-3deg);
}
.wallet img{
  width: 30px;
  height: 30px;
}
#display{
  width: 100%;
  height: 300px;
  margin-top: 35px;
  padding-right: 25px;
}
#notifier {
  width: 100%;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}
#notifier::-webkit-scrollbar {
  display: none;
}
#scroll {
  display: flex;
  padding-left: 5px;
}
.notifierBoxes {
  background-color: #f0f2f5;
  color: rgba(163,163,163, 1);
  font-size: 14px;
  min-width: 70px;
  height: 30px;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  /* border: 1.5px solid rgba(163,163,163,0.353); */
  padding: 10px;
}
#priceViewDiv{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
}
#priceViewDiv div{
  display: flex;
  flex-direction: column;
}
#priceViewDiv h1{
  font-size: 22px;
}
#priceViewDiv span{
  font-size: 12px;
  color: rgba(163,163,163, 1);
}
#priceViewDiv p{
  font-size: 15px;
  color: green;
  font-weight: 450;
  padding: 8px;
}
#buyAndSellDiv{
  width: 100%;
  height: 200px;
  padding: 20px;
}
#BASHeader{
  width: 100%;
}
#BASHeader div{
  width: 85px;
  padding: 8px;
  font-size: 12px;
  height: 22px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50px;
}
#BASHeader i{
  font-size: 8px;
  padding-right: 3px;
  padding-top: 1px;
}
#BASBody{
  width: 100%;
  display: flex;
  padding-top: 12px;
  /* align-items: center; */
  font-size: 13px;
  color: rgba(81,81,81,0.953);
}
#BASBody div{
  display: flex;
  flex-direction: column;
}
#BASBody p{
  padding-top: 8px;
}
#BASBody img{
  width: 60px;
  height: 60px;
  margin-right: 8px;
}
#btns{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}
#btns div{
  width: 46%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}
#buyBtn{
  background-color: green;
}
#sellBtn{
  background-color: red;
}
/* Glossy Reflection Effect */
#btns div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}