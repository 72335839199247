#bg{
  width: 100%;
  padding: 12px;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  gap: 12px;
}
#bg img{
  width: 93dvw;
  aspect-ratio: 32/8;
  border-radius: 8px;
  scroll-snap-align: center;
}
@media(min-width: 450px){
  #bg img{
    width: 380px;
  }
}