#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  position: absolute;
  top: 0;
  padding-top: 65px;
  z-index: 999;
}
#box{
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
}
#header{
  width: 100%;
  height: 65px;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); */
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
}
#header p{
  font-size: 18px;
  font-weight: 500;
}
#header i{
  padding: 8px;
  font-size: 15px;
}
#body{
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 15px;
  padding-top: 25px;
}
#mainProfilePicture{
  width: 100px;
  height: 100px;
  padding: 2.5px;
  outline: 2.5px solid #f40057;
  border-radius: 50%;
  margin: 10px;
}
.linear{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.miniTitle{
  font-size: 14px;
  font-weight: 500 !important;
  margin-bottom: 6px;
  color: #555;
}
#horizontalScrollview{
  display: flex;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow-x: auto;
  /* padding-left: 10px; */
}
#scrollDiv{
  display: flex;
  padding-left: 5px;
  padding: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
}
#horizontalScrollview::-webkit-scrollbar{
  display: none;
}
#scrollDiv div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 480;
  color: #464646;
}
#scrollDiv img{
  width: 60px;
  height: 60px;
  margin: 6px;
  margin-bottom: 3px;
}
#inputDiv{
  width: 100%;
  padding: 12px;
}
#inputDiv input{
  width: 100%;
  height: 45px;
  padding: 6px;
  margin-top: 6px;
  outline: none;
  border: 1.5px solid darkgray;
  border-radius: 5px;
  margin-bottom: 4px;
}
#textAlertDiv{
  padding: 2px;
  font-size: 14px;
  color: #555;
  display: flex;
  justify-content: space-between;
}
#textAlertDiv span{
  font-size: 10px;
  color: red;
}
#btnsDiv{
  width: 100%;
  display: flex;
  padding: 20px;
}
#btnsDiv button{
  width: 100%;
  height: 40px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 15px;
}
#btn1 {
  position: relative;
  background: linear-gradient(to bottom, #d62976, #f40057);
  color: #ffffff;
  /* border: 1px solid #7f0059a8; */
  padding: 6px;
  border-radius: 8px; 
  font-size: 16px;
  box-shadow: 
    inset 0px -2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

/* Glossy Reflection Effect */
#btnsDiv button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}