.bg {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  overflow-y: scroll;
}

.header {
  width: 100%;
  height: 65px;
  background-color: #ffffff;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); */
  color: black;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.header p {
  font-size: 18px;
}

.header i {
  cursor: pointer;
  font-size: 15px;
  margin-right: 10px;
}

.aboutContainer {
  max-width: 900px;
  margin: 15px auto;
  background-color: #ffffff;
  border-radius: 15px;
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
  padding: 30px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.8;
  color: #333;
}

.aboutContainer h1 {
  text-align: center;
  font-size: 2.5em;
  color: #2c3e50;
  margin-bottom: 20px;
}

.aboutContainer p {
  margin-bottom: 15px;
  font-size: 1.1em;
}

.aboutContainer strong {
  /* color: #3498db; */
  /* color: #e2009f8f; */
  color: #aa0062;
}
.aboutContainer div{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.aboutContainer img{
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin: 3px;
}



/* Container Styling */
.taccontainer {
  background-color: #f5f5f5;
  min-height: 100vh;
}

/* Content Styling */
.taccontent {
  background-color: white;
  padding: 25px;
  border-radius: 15px;
  /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); */
  margin-top: 15px;
  line-height: 1.8;
  font-size: 16px;
  color: #333;
}

.taccontent p {
  margin-bottom: 20px;
}

.taccontent strong {
  /* color: #1e3c72; */
  color: #aa0062;
  font-weight: bold;
}



/* Container Styling */
.ppcontainer {
  background-color: #f5f5f5;
  min-height: 100vh;
}

/* Content Styling */
.ppcontent {
  background-color: white;
  padding: 25px;
  border-radius: 15px;
  /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); */
  margin-top: 15px;
  line-height: 1.8;
  font-size: 16px;
  color: #333;
}

.ppcontent p {
  margin-bottom: 20px;
}

.ppcontent strong {
  color: #aa0062;
  font-weight: bold;
}
