/* CSS code for Reward claim radial screen */ 
#bg{ /* screen background */
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0 , 0.9);
   animation: 0.3s openAnimation linear;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
}
.radialPattern { /* radial */
  height: 200%;
  aspect-ratio: 1/1;
  opacity: 0.05;
  background: 
  radial-gradient(circle, rgba(0, 0, 0, 0.2), transparent),
  repeating-conic-gradient(gold 0deg 15deg, transparent 15deg 30deg);
  animation: 25s rotateRadial linear infinite;
}
@keyframes rotateRadial{
  0%{
    transform: rotate(0deg);
  }
  0%{
    transform: rotate(360deg);
  }
}
@keyframes openAnimation {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

#rewardBox{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#rewardDiv {
  width: 80px;
  height: 100px;
  background: linear-gradient(180deg, black, black, #0b0075ba);
  object-fit: contain;
  padding: 8px;
  border-bottom: 3px solid #f40057;
  box-shadow: 1px 1px 4px #0b0075ba;
  animation: 0.12s zoomInAnimation linear;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#rewardDiv p{
  position: absolute;
  bottom: 3px;
  right: 4px;
  color: white;
  font-size: 9px;
  font-family: normal;
  opacity: 0.8;
}
#rewardDiv img{
  width: 100%;
}
@keyframes zoomInAnimation{
  0%{
    transform: scale(0, 0);
  }
  100%{
    transform: scale(100%, 100%);
  }
}
#closeText{
  transform: translateY(100px);
  color: gray;
  font-size: 12px;
  font-weight: 350;
  animation: 3s loopOpacity linear infinite;
}
@keyframes loopOpacity{
 0%, 100%{
   opacity: 1;
 }
 50%{
   opacity: 0.5;
 }
}