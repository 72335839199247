#bg{
  width: 100%;
  background: linear-gradient(180deg, #0f1272, #141535);
  border-radius: 15px;
  box-shadow: 0px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  padding: 12px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.linear{
  width: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  background-color: #02030a;
  padding: 5px;
}
.linear p{
  color: white;
  font-size: 11px;
  font-weight: 420;
  padding: 8px;
}
.linear img{
  width: 40px;
  height: 40px;
  padding: 1px;
  background: linear-gradient(180deg, transparent, #0f1272);
}
.space{
  width: 1px;
  height: 1px;
  flex-grow: 1;
}
.linear span{
  color: white;
  font-size: 11px;
  margin-right: 8px;
}
.linear button{
  padding: 5px;
  min-width: 55px;
  border: none;
  color: black;
  background-color: silver;
  border-radius: 20px;
  font-size: 10px;
}