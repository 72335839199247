#bg{
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
#circle{
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #ffffff;
  padding: 8px;
}
#loader{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2.3px solid transparent;
  border-top: 2.3px solid #f40057;
  animation: 800ms load infinite linear;
}
@keyframes load{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}