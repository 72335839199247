@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html, body, #root{
  width: 100%;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  color: black;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  font-family: "Ubuntu", serif;
  font-weight: 400;
  font-style: normal;
}
#root::-webkit-scrollbar{
  display: none;
}
#root textarea{
  font-family: "Ubuntu", serif!important;
  font-weight: 400!important;
  font-style: normal!important;
}
#root i{
  cursor: pointer;
}


@media(min-width: 450px){
  #root{
    width: 400px;
    position: relative;
  }
  body{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
}

