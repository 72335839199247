#bg{
  width: 100%;
  height: 100%;
  padding: 20px;
}
#profile{
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.gap{
  width: 100px;
  height: 100px;
  flex-grow: 1;
}
#dp{
  width: 95px;
  height: 95px;
  background-color: #d8d8d8c3;
  border-radius: 50%;
}
.options{
  width: 100%;
  height: 55px;
  background-color: #d8d8d8c3;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 10px;
}
.text{
  width: 70px;
  height: 15px;
  background-color: #d8d8d8c3;
  border-radius: 20px;
}
.smallText{
  width: 40px;
  height: 15px;
  margin-top: 10px;
  background-color: #d8d8d8c3;
  border-radius: 20px;
}