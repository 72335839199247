#bg{
  width: 100%;
  height: 100%;
  background-color: #15042E;
  display: flex;
  flex-direction: column;
}
#header{
  width: 100%;
  height: 60px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: #342960;
}
#headerBtns{
  display: flex;
}
#header i{
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color:  #ffffff6c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
#wallet{
  padding: 12px;
  padding-left: 4px;
  background-color: #ffffff6c;
  color: #ffffff;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  overflow: visible;
  display: flex;
  align-items: center;
  margin-right: 0px;
  position: relative;
  overflow: hidden;
}
#wallet i{
  margin-right: 4px;
  background-color: transparent;
}
#wallet::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-50%) rotate(-3deg);
}
#notifier {
  width: 100%;
  background-color: black;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}
#notifier::-webkit-scrollbar {
  display: none;
}
#scroll {
  display: flex;
  padding-left: 5px;
}
.notifierBoxes {
  color: white;
  min-width: 80px;
  height: 35px;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #ffffff6c;
  padding: 10px;
}
#body{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
#rocket{
  width: 200px;
  height: 200px;
  transform: rotate(45deg);
  animation: 2s rocketAni;
}
#scoreDiv{
  display: flex;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 500;
}
#footer{
  width: 100%;
  height: 65px;
  padding: 15px;
  background: linear-gradient(180deg, #9f1bd2, blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#winningCash{
  width: 30%;
  height: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #15042E;
  border-radius: 5px;
  box-shadow: 0px 1px 30px #243158 inset;
}
#button{
  width: 68%;
  height: 50px;
  border: none;
  color: white;
  background: linear-gradient(to bottom, #008b1e, #00ce2c);
  border-radius: 5px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}
#button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-1.5deg);
}
#button2{
  width: 68%;
  height: 50px;
  border: none;
  color: white;
  background: linear-gradient(180deg, #43007c, #17002b);
  border-radius: 5px;
  font-weight: 500;
}


@keyframes rocketAni{
  0%{
    transform: translate(-100%, 100%) rotate(45deg);
  }
  100%{
    transform: translate(0, 0) rotate(45deg);
  }
}