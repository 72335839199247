#bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#box{
  width: 80%;
  /* aspect-ratio: 1/1.3; */
  /* background-color: #fdffec; */
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  padding: 8px;
 /*  padding-top: 10%; */
}
#exitBtn{
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: red;
  border: none;
  position: relative;
  overflow: hidden;
  color: white;
  font-size: 18px;
  position: absolute;
  top:  -8px;
  right: -8px;
}
#exitBtn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
#header{
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #555;
}
.linear{
  width: 100%;
  height: 33.33333%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.boxes{
  width: 60px;
  height: 60px;
  background: linear-gradient(to bottom, #d62976, #f40057);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}
.boxes i{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.giftAnimation{
  animation: giftAni 1s linear;
}
@keyframes giftAni{
  0%, 100%{
    transform: rotate(0deg);
  }
  20%, 60%{
    transform: rotate(20deg);
  }
  40%, 80%{
    transform: rotate(-20deg);
  }
}
/* Glossy Reflection Effect */
.boxes::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-65%) rotate(-3deg);
}
#selectBox{
  width: 100%;
  aspect-ratio: 1/1;
}

#footer{
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #a3003f;
  font-weight: 500;
}