#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  position: absolute;
  top: 0;
  padding-top: 65px;
  z-index: 999;
}
#header{
  width: 100%;
  height: 65px;
/*   box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); */
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
}
#header p{
  font-size: 18px;
  font-weight: 500;
}
#header i{
  padding: 8px;
  font-size: 15px;
}
#body{
  width: 100%;
  flex-grow: 1;
  background-color: white;
  margin-top: 15px;
  padding: 20px;
}
#body textarea{
  width: 100%;
  height: 150px;
  resize: none;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: 1.5px solid darkgray;
  border-radius: 5px;
  
}
#footer{
  width: 100%;
  padding: 20px;
}
#footer button {
  width: 100%;
  height: 40px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(to bottom, #d62976, #f40057);
  color: #ffffff;
  /* border: 1px solid #7f0059a8; */
  padding: 6px;
  border-radius: 8px; 
  font-size: 16px;
  box-shadow: 
    inset 0px -2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

/* Glossy Reflection Effect */
#footer button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-1.5deg);
}

#optionsLinear {
  padding-top: 0px;
  padding-bottom: 35px;
}
#optionsLinear h1{
  font-size: 16px;
  padding-bottom: 10px;
}
.options{
  width: 100%;
  margin-top: 10px;
  border-radius: 6px;
  background-color: #f0f2f5;
  padding: 12px;
  position: relative;
  overflow: hidden;
}
/* Glossy Reflection Effect */
.options::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-1.5deg);
}
.options i{
  color: #d62976;
  margin-right: 10px;
}
.options span{
  font-weight: 500;
  color: #555;
  font-size: 15px;
}
#textCounterDiv{
  color: #555;
  font-size: 14px;
  padding: 2px;
  display: flex;
  justify-content: flex-end;
}