#bg{ /* dorwer main Bg */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  background-color: rgba(0, 0,0, 0.4);
  display: flex;
}

.drowerBox{ /* drower box  */
  width: 80%;
  height: 100%;
  background-color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  animation: 0.2s openAnimation linear;
}
@keyframes openAnimation{/*drower Animation */
  0%{
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(0px);
  }
}
#blank{
  width: 25%;
  height: 100%;
}


.splashLinear{ /* Drower Splash boxes */
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.splashLinear2{
  display: flex;
  flex-direction: column;
}
.splashCircle{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #d8d8d8c3;
}
.splashTexts{
  height: 10px;
  border-radius: 20px;
  background-color: #d8d8d8c3;
  margin: 5px;
  margin-left: 10px;
}
.splashSettingBox{
  width: 100%;
  padding-top: 10px;
}
#footer{
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: gray;
  position: absolute;
  bottom: 30px;
  left: 0;
}