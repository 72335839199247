#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  overflow-y: scroll;
  scrollbar-width: none;
}
#bg::-webkit-scrollbar{
  display: none;
}
#header{
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #ffffff;
  z-index: 99;
}
.linear{
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
#menuBtn{
  font-size: 20px;
  padding: 8px;
  margin-left: 10px;
}
#logo{
   height: 60%;
   padding-top: 2px;
   padding-bottom: 2px;
}
#langBtn{
  width: 80px;
  padding: 12px;
  background-color: #f4005730;
  color: #f40057;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
#langBtn i{
  padding-right: 5px;
}
#langBtn select{
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: bold;
  color: #f40057;
  background-color: transparent;
}
#langBtn::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) rotate(-3deg);
}
#heading{
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 40px;
  color: rgba(0, 0, 0, 0.9);
  font-family: normal;
  padding-top: 70px;
}
#bg span{
  color: #f40057;
}
#paragraph{
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  padding: 12px;
  font-weight: 420;
  font-size: 18px;
}

#body{
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#btn{
  width: 50%;
  height: 40px;
  background-color: #f40057;
  color: white;
  border-radius: 50px;
  border: none;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  animation: 1s btnAnimation linear infinite;
}
@keyframes btnAnimation{
  0%, 100%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.05);
  }
}
#btn::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
#btn img{
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
}
#screenShots{
  margin-top: 30px;
  width: 100%;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

#ratingDiv{
  width: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
#ratingDiv h3{
  font-style: italic;
  font-size: 18px;
  margin-bottom: 4px;
}
#ratingBox{
  width: 100%;
  background-color: #f0f2f5;
  border-radius: 15px;
  margin-top: 12px;
  display: flex;
  overflow: hidden;
}
#leftDiv{
  width: 45%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
#leftDiv div{
  display: flex;
  justify-content: center;
  width: 100%;
}
#leftDiv h1{
  font-size: 55px;
  color: #f40057;
}
#leftDiv i{
  padding: 2px;
  color: #f40057;
}
#leftDiv p{
  margin-top: 10px;
  font-weight: bold;
}
#rightDiv{
  width: 55%;
  height: 100%;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.rightDivsLinear{
  width: 100%;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rightDivsLinear p{
  font-size: 12px;
  padding-left: 4px;
  padding-right: 4px;
}
.progressDiv{
  width: 70%;
  height: 8px;
  background-color: darkgray;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.progressDiv::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-60%) rotate(-1deg);
}
#progress1{
  width: 10%;
  height: 100%;
  background-color: #f40057;
  border-radius: 50px;
}
#progress2{
  width: 3%;
  height: 100%;
  background-color: #f40057;
  border-radius: 50px;
}
#progress3{
  width: 2%;
  height: 100%;
  background-color: #f40057;
  border-radius: 50px;
}
#progress4{
  width: 21%;
  height: 100%;
  background-color: #f40057;
  border-radius: 50px;
}
#progress5{
  width: 84%;
  height: 100%;
  background-color: #f40057;
  border-radius: 50px;
}

.description{
  width: 100%;
  padding: 15px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.8;
}
.description strong{
  color: #f40057;
}
.description p {
  margin-bottom: 15px;
  font-size: 1.1em;
}

.faqsBg{
  width: 100%;
  padding: 15px;
}
.faqsDivs{
  width: 100%;
  margin-top: 15px;
  background-color: #f0f2f5;
  border-radius: 15px;
  padding: 25px;
  display: flex;
  flex-direction: column;
}
.faqsDivs h4{
  font-size: 17px;
  font-weight: 410;
  width: 90%;
}
.faqsDivs i{
  width: 10%;
  height: 100%;
  padding: 5px;
  /* padding-left: 12px; */
  font-size: 15px;
}
.faqsBg h3{
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: 18px;
  margin-bottom: 8px;
}
.faqsBg span{
  color: #f40057;
}
.faqsHeadingsDiv{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.fullFaqsAnswers{
  width: 100%;
  font-family: 'Roboto', sans-serif;
  line-height: 1.7;
  padding-top: 15px;
  color: #555;
}

/*excitedBottomDrower*/

#excitedBottomDrower{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: end;
}
#eDBBox{
  width: 100%;
  /* height: 300px; */
  background-color: #ffffff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  animation: 0.2s eBDAnimation ease-in-out;
  display: flex;
  flex-direction: column;
}
@keyframes eBDAnimation{
  0%{
    transform: translateY(100%);
  }
  100%{
    transform: translateY(0);
  }
}
#eDBHeader{
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#eDBHeader div{
  width: 100px;
  height: 5px;
  border-radius: 20px;
  background-color: lightgray;
}
#eDBHeader i{
  font-size: 20px;
  position: absolute;
  right: 20px;
  top: 15px;
}
#eDBBody{
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}
#eDBBody h3{
  font-size: 18px;
}
#eDBBody p{
  padding: 15px;
  font-size: 14px;
  color: gray;
  text-align: center;
}
#eDBStarsDiv{
  width: 100%;
  display: flex;
  justify-content: center;
}
#eDBStarsDiv i{
  font-size: 25px;
  margin: 3px;
  color: lightgray;
}
#eDBBody button{
  width: 90%;
  height: 35px;
  border: none;
  outline: none;
  background-color: #f40057;
  color: #ffffff;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
#eDBBody button::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-2deg);
}

/*Drowerr*/
#drower{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
#drowerBox{
  width: 100%;
  background-color: #ffffff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  animation: 0.2s drowerAnimation ease-in-out;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}
@keyframes drowerAnimation{
  0%{
    transform: translateY(-100%);
  }
  100%{
    transform: translateY(0);
  }
}
#drowerHeader{
  width: 100%;
  padding: 15px;
  padding-bottom: 0;
}
#drowerHeader i{
  font-size: 20px;
}
#drowerBox h3{
  width: 100%;
  padding: 5px;
  text-align: center;
}