#bg{
  width: 100%;
  height: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
}
#story{
  width: 100%;
  height: 80%;
  flex-grow: 1;
  background-image: url("../../assets/abstractBg1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
#storyHeader{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 18px;
  padding-left: 15px;
  padding-right: 15px;
}
#loaderDiv{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#top{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-top: 15px;
}
#top i{
  font-size: 20px;
}
#top img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
#top p{
  font-size: 15px;
  padding: 8px;
  font-weight: 500;
}
.linear{
  display: flex;
  align-items: center;
}
#loaderContainer{
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background-color: gray;
  overflow: hidden;
}
#loader{
  height: 100%;
  background-color: white;
  border-radius: 10px;
}
#footer{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
#footer button{
  width: 95%;
  height: 40px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 15px;
  background: linear-gradient(180deg, #f4005730, #e2009f4d);
  color: #ffffff;
  box-shadow: 0px 3px 0px #e2009f61 ;
  position: relative;
  overflow: hidden;
}
#arrow{
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 50px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}