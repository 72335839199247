#bg{
  width: 100%;
  height: 55px;
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.1);
  color: gray;
  display: flex;
  align-items: center;
  background-color: #f0f2f5;
  z-index: 888;
  position: relative;
}
.icons{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
}
.btns{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96%;
  flex-grow: 1;
  flex-direction: column;
}
.btns p{
  font-size: 10px;
  padding-top: 5px;
  font-weight: 500;
  width: 65px;
  text-align: center ;
}
#btn1{
  height: 95%;
  padding: 3px;
  outline: 2px solid #e2009f;
  border-radius: 50%;
  aspect-ratio: 1/1;
  background-color: #f0f2f5;
  position: absolute;
  top: -50%;
}
.youIcon{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: translateY(-3px);
}