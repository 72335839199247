#bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#linear{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}
#icon{
  /* width: 100px; */
  height: 150px;
  border-radius: 5px;
}
#loadingText{
  font-size: 15px;
  padding: 10px;
  color: darkgray;
  font-weight: 400;
}
#footer{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  align-items: center;
  font-size: 13px;
  color: #aa0062;
  font-weight: 400;
  padding: 10px;
  transform: translateY(-100px);
}
#loaderBg{
  width: 200px;
  height: 12px;
  background-color: #d8d8d8c3;
  border-radius: 3px;
  /* box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2) inset; */
  overflow: hidden;
  margin-top: 10px;
  position: relative;
}
#loader{
  height: 100%;
  width: 20%;
  background: linear-gradient(90deg, #d62976, #962fbf, #4f5bd5);
}
/* Glossy Reflection Effect */
#loaderBg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  transform: translateY(-50%) rotate(-0.5deg);
}