.gradientBorder{
  width: 38px;
  height: 38px;
  padding: 2px;
  background: linear-gradient(90deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
  border-radius: 50%;
  margin-right: 5px;
  aspect-ratio: 1/1;
}
#exp{
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 3px;
  border-radius: 50%;
}
.prizeText{
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 3px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
#bg{
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  /* background-color: green; */
  scrollbar-width: none;
}
#bg::webkit-scrollbar{
  display: none;
}
.rewardsBg{
  padding: 12px;
  padding-right: 0px;
  scroll-snap-align: start;
}
.rewardDiv{
  position: relative;
  width: 93dvw;
  aspect-ratio: 2.6/1;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  padding-top: 12px;
  overflow: hidden;
}
@media(min-width: 450px){
  .rewardDiv{
    width: 380px;
  }
}

.linear1{
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
}
.linear2{
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.textLinear{
  display: flex;
  flex-direction: column;
}
.textLinear span{
  font-size: 13px;
  color: #555;
  padding: 1px;
}
.textLinear p{
  padding: 1px;
}
.btn1{
  width: 42%;
  height: 60%;
  border: none;
  border-radius: 5px;
  background-color: #6f00a027;
  color: #6f00a0ae;
  font-weight: 450;
  position: relative;
  overflow: hidden;
}
.btn2{
  width: 42%;
  height: 60%;
  border: none;
  border-radius: 5px;
  background-color: #3ebb012b;;
  color: #18a000e8;
  font-weight: 450;
  position: relative;
  overflow: hidden;
}
.linear2 button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
.tags{
  height: 18px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 0 0 0 4px;
  right: 0;
  top: 0;
  background-color: red;
  /* background: linear-gradient(to bottom, #d62976, #f40057); */
  color: white;
  font-size: 10px;
}
.tags::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
