#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  position: relative;
  padding-top: 60px;
  overflow-y: scroll;
  scrollbar-width: none;
  color: #555;
}
#bg::-webkit-scrollbar{
  display: none;
}
#header{
  width: 100%;
  height: 65px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7px;
  background-color: white;
  position: absolute;
  top: 0;
}
#header p{
  font-size: 18px;
  font-weight: 500;
}
#header i{
  padding: 8px;
  font-size: 15px;
}
#tabsBg{
  width: 100%;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); */
  background-color: white;
  display: flex;
}
.enableTab{
  border-bottom: 2px solid #f40057;
}
.tabs{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  color: #f40057;
  font-weight: 500;
  padding: 10px;
  padding-top: 3px;
}
#soundControlBtn{
  width: 40px;
  height: 20px;
  font-size: 10px;
  background: linear-gradient(to bottom, #f4005730, #e2009f4d);
  color: #f40057;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  overflow: hidden;
}
/* Glossy Reflection Effect */
#soundControlBtn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) rotate(-3deg);
}
#soundControlBtn i{
  font-size: 12px;
}

#body{
  width: 100%;
  flex-grow: 1;
  margin-top: 12px;
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
}

#page1{
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#topInfo{
  width: 92%;
  height: 50px;
  border-radius: 8px;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); */
  margin-top: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}
#topInfo p{
  font-size: 16px;
  font-weight: 500;
}
#topInfo span{
  font-size: 15px;
  color: gray;
  font-weight: normal;
}
.linear{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.linear h1{
  font-size: 16px;
  color: #f40057;
  padding: 3px;
}
.linear h2{
  font-size: 15px;
  color: #000;
  padding: 3px;
}
#shareBox{
  width: 92%;
  aspect-ratio: 10/6;
  border-radius: 8px;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); */
  margin-top: 15px;
  padding: 15px;
  background-color: white;
}
#shareBox h1{
  font-size: 16px;
  font-size: 500;
  padding: 12px;
  width: 100%;
}
#socialDiv{
  width: 100%;
  display: flex;
  justify-content: space-around;
}
#socialDiv div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 450;
  font-size: 12px;
  gap: 2px;
}
.socialIcons{
  width: 35px;
  height: 35px;
  margin: 5px;
  border-radius: 50%;
  background-color: #e2009f4d;
}
#socialDiv i{
  width: 35px;
  height: 35px;
  margin: 5px;
  border-radius: 50%;
  background-color: #e2009f4d;
  display: flex;
  justify-content: center;
  align-items: center;
}
#codeDiv{
  width: 100%;
  height: 45px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #555;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px dashed #f40057;
  position: relative;
  overflow: hidden;
  padding: 12px;
  gap: 6px;
}
#codeDiv p{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#detailsBox{
  width: 92%;
 /*  aspect-ratio: 10/8; */
  border-radius: 8px;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); */
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
#detailsBoxHeader{
  width: 100%;
  display: flex;
  justify-content: center;
}
#detailsBoxHeader h1{
  font-size: 16px;
  font-size: 500;
  padding: 12px;
  width: 100%;
}
.detailsBoxLinears{
  width: 100%;
  display: flex;
  margin-bottom: 8px;
  margin-top: 8px;
}
.detailsBoxLinears img{
  width: 55px;
  height: 55px;
}
.detailsBoxLinears div{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.detailsBoxLinears p{
  font-size: 16px;
  font-weight: 500;
  padding: 3px;
}
.detailsBoxLinears span{
  font-size: 12px;
  padding: 3px;
  color: gray;
  font-family: normal;
}


#page2{
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
#page2 img{
  width: 170px;
  height: 170px;
}
#page2 p{
  font-size: 16px;
  font-weight: 500;
  color: #555;
  
}