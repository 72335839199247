#bg{
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
}
#bg2{
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f0f2f5;
}
.hl{
  width: 100%;
  height: 10px;
  background-color: #f0f2f5;
}
#body{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#header{
  width: 100%;
  height: 65px;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); */
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7px;
  background-color: #ffffff;
  margin-bottom: 15px;
}
#header p{
  font-size: 18px;
  font-weight: 500;
}
#header i{
  padding: 8px;
  font-size: 15px;
}
.gap{
  flex-grow: 1;
  height: 80px;
  border-right: 1px solid lightgray;
}
#wallet{
  padding: 12px;
  padding-left: 4px;
  background-color: #f4005730;
  color: #f40057;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  overflow: visible;
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
#wallet i{
  padding-right: 8px !important;
  font-size: normal;
}
#wallet::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) rotate(-3deg);
}


#walletDiv{
  width: 92%;
  height: 80px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.walletBoxes{
  width: 31%;
  height: 100%;
  background-color: #ffffff;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #555;
}
.walletBoxes p{
  font-size: 13px;
}
.walletBoxes i{
  font-size: 18px;
}


#profile{
  width: 92%;
  display: flex;
  align-items: center;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  background-color: white;
}
#profileDiv{
  width: auto;
  height: auto;
  display: flex;
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 35px;
  justify-content: center;
  align-items: center;
}
#profileDetails{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
}
#profileDetails h1{
  font-size: 15px;
  font-weight: 600;
  color: black;
  padding: 2px;
}
#profileDetails h2{
  font-size: 12px;
  font-weight: 550;
  color: black;
  padding: 2px;
}
#profileDetails span{
  font-size: 10px;
  color: #a3003f;
  padding: 2px;
}
#profileDetails i{
  font-size: 8px;
  color: #a3003f;
  padding: 2px;
}
#dpDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
#userDp{
  width: 85px;
  height: 85px;
  margin: 5px;
  border-radius: 50%;
  background-image: url('../../assets/user.png');
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: right;
  border: 2.5px solid transparent;
  outline: 2.5px solid #f40057;
}
#profileDiv p{
  width: 30px;
  height: 30px;
  padding: 7px;
  color: white;
  background-color: #f40057;
  text-align: center;
  font-size: 10px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 3px;
  margin-right: 7px;
  font-weight: 550;
  border: 2.5px solid white;
  overflow: hidden;
}
#profileDiv p::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}
#badgeDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: black;
}
#badgeDiv img{
  width: 60px;
  height: 60px;
}
#badgeDiv p{
  font-size: 12px;
  font-weight: 500;
}
#badgeDiv div{
  display: flex;
  align-items: center;
}
#badgeDiv div i{
  font-size: 10px;
  padding-bottom: 3px;
}
#badgeDiv div p{
  font-size: 12px;
  font-weight: 600;
  padding: 2px;
}



#settingsDiv{
  width: 92%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #555;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); */
  background-color: #ffffff;
  border-radius: 10px;
}
.headings{
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
   margin-bottom: 15px;
}
.settingOptions{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 35px;
  padding-right: 35px;
}
.settingOptions i{
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  margin-right: 15px;
  position: relative;
  overflow: hidden;
  color: #f40057;
}
.settingOptions div{
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
}
.settingOptions p{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 13px;
  color: gray;
}
.hl2{
  width: 85%;
  height: 0.5px;
  background-color: #f4005730;
}