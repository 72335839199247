#bg{
  width: 100%;
  aspect-ratio: 10/1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 30px;
  margin-top: 30px;
  font-size: 12px;
  color: gray;
}
#bg p{
  font-size: 12px;
  color: gray;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 2px;
}
#bg div{
  display: flex;
}
#firstLinear{
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 4px;
  font-size: 14px !important;
}
#firstLinear p{
  font-weight: 415;
}
#imgLinear{
  width: 100%;
  padding: 4px;
  display: flex;
  justify-content: space-around;
}
#imgLinear img{
  width: 70px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1));
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1));
}