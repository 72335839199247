/* CSS code for toast component */

.toast { /* Main Toast */
  position: absolute;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%);
  bottom: -100%; /* Start off-screen */
  height: auto;
  padding: 6px 12px 6px 12px;
  color: white;
  background-color: #f40057!important;
  transition: bottom 0.3s ease-in-out;
  z-index: 999;
  text-align: center;
  white-space: nowrap;
}

.open { /* Open state for animation */
  bottom: 90px; /* Move into view */
}

.toast::before,
.toast::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
}
.toast::before {
  left: -50px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #f40057);
}
.toast::after {
  right: -50px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0), #f40057);
}
