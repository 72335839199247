#bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
#confirm{
  width: 80%;
  min-height: 170px;
  background-color: white;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  border-radius: 10px;
  /* border: 2px solid #f40057; */
}
#header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
#header i{
  font-size: 40px;
  color: #f40057;
}
#header p {
  font-weight: 500;
/*   padding-left: 8px; */
  font-size: 18px;
}
#body{
  width: 100%;
  height: auto;
  padding: 15px;
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#body p{
  font-size: 14px;
  color: #35282e;
  padding: 6px;
}
#footer{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
#footer button{
  width: 45%;
  height: 35px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 15px;
}

#cancleBtn{
  position: relative;
  background: linear-gradient(to bottom, #f4005730, #e2009f4d);
  color: #f40057;
  /* border: 1px solid #7f0059a8; */
  padding: 6px;
  border-radius: 8px; 
  font-size: 16px;
   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
#okBtn {
  position: relative;
  background: linear-gradient(to bottom, #d62976, #f40057);
  color: #ffffff;
  /* border: 1px solid #7f0059a8; */
  padding: 6px;
  border-radius: 8px; 
  font-size: 16px;
  box-shadow: 
  inset 0px -2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
/* Glossy Reflection Effect */
#footer button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}