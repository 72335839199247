#bg{
  width: 100%;
  height: 100%;
  background-color: #010221;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
}
#header{
  width: 100%;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 18px;
}
#header i{
  font-size: 15px;
  margin-right: 12px;
}
#body{
  width: 100%;
  height: 100px;
  flex-grow: 1;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#vsImg{
  width: 80px;
}
.linear{
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.linear p{
  padding: 6px;
  font-weight: 500;
}
.linear img{
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 2.5px;
  z-index: 2;
  will-change: transform;
}
.ani{
  animation: rotateAnimation 3s linear infinite;
}
.profilePictureBg{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(90deg, #d62976, #962fbf, #4f5bd5);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotateAnimation{
  0%, 100%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
}
#roomKeyDiv{
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 8px;
  background-color: white;
  color: black;
  font-weight: 500;
}
#roomKeyDiv i{
  padding: 5px !important;
}
#timmerDiv{
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #f0f2f5;
  font-size: 14px;
  border-radius: 3px;
}
#timmerDiv i{
  margin-right: 6px;
  font-size: 13px;
  color: red;
}
#timmerDiv span{
  color: red;
}