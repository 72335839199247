#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  overflow-y: scroll;
}
#header{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
}
.linear{
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
#menuBtn{
  font-size: 20px;
  padding: 8px;
  margin-left: 10px;
}
#logo{
   height: 60%;
   padding-top: 2px;
   padding-bottom: 2px;
}
#helpBtn{
  width: 80px;
  padding: 12px;
  background-color: #f4005730;
  color: #f40057;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
#helpBtn i{
  padding-right: 5px;
}
#helpBtn::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) rotate(-3deg);
}
#heading{
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 40px;
  color: rgba(0, 0, 0, 0.9);
  font-family: normal;
}
#bg span{
  color: #f40057;
}
#paragraph{
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  padding: 12px;
  font-weight: 450;
  font-size: 18px;
}

#body{
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#btn{
  width: 60%;
  height: 40px;
  background-color: #f40057;
  color: white;
  border-radius: 50px;
  border: none;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
#btn::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-50%) rotate(-3deg);
}
#btn img{
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
}
#screenShots{
  margin-top: 30px;
  width: 100%;
}

#ratingDiv{
  width: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
#ratingDiv h3{
  font-style: italic;
}
#ratingBox{
  width: 100%;
  background-color: #f0f2f5;
  border-radius: 15px;
  margin-top: 12px;
  display: flex;
  overflow: hidden;
}
#leftDiv{
  width: 45%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
#leftDiv div{
  display: flex;
  justify-content: center;
  width: 100%;
}
#leftDiv h1{
  font-size: 55px;
  color: #f40057;
}
#leftDiv i{
  padding: 2px;
  color: #f40057;
}
#leftDiv p{
  margin-top: 10px;
  font-weight: bold;
}
#rightDiv{
  width: 55%;
  height: 100%;
  padding: 10px;
}
.rightDivsLinear{
  width: 100%;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rightDivsLinear p{
  font-size: 12px;
  padding-left: 4px;
  padding-right: 4px;
}
.progressDiv{
  width: 70%;
  height: 8px;
  background-color: darkgray;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.progressDiv::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-60%) rotate(-1deg);
}
#progress1{
  width: 10%;
  height: 100%;
  background-color: #f40057;
  border-radius: 50px;
}
#progress2{
  width: 3%;
  height: 100%;
  background-color: #f40057;
  border-radius: 50px;
}
#progress3{
  width: 2%;
  height: 100%;
  background-color: #f40057;
  border-radius: 50px;
}
#progress4{
  width: 21%;
  height: 100%;
  background-color: #f40057;
  border-radius: 50px;
}
#progress5{
  width: 84%;
  height: 100%;
  background-color: #f40057;
  border-radius: 50px;
}