@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
#bg{
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, #00145b, #000825);
  display: flex;
  flex-direction: column;
  position: relative;
}
#boardLinear{
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
#board{
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #000000; */
}
.columnLinear{
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
}
.boxes{
  width: 100%;
  aspect-ratio: 1/1;
  background-color: #000825;
  outline: 3px solid #0c72ed;
  box-shadow: 
    0 0 5px  rgba(12,114,237,0.2),
    0 0 10px  rgba(12,114,237,0.2),
    0 0 15px  rgba(12,114,237,0.2),
    0 0 20px  rgba(12,114,237,0.2);
  border-radius: 8px;
  margin: 6px;
  margin-bottom: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 90px;
  color: orange;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}
.boxes img {
  width: 100%;
  height: 100%;
}

#top{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: white;
}
.timmer{
 width: 55px;
 height: 20px;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 20px;
 background-color: #f6f6f6;
 color: green;
 font-size: 11px;
 margin-top: 3px;
}
#top div{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}
#top img{
  width: 60px;
  height: 60px;
  padding: 6px;
}
.topBox1{
  width: 65px !important; 
  height: 65px !important;
  padding: 8px;
  border-radius: 8px;
  border: 3px solid #0c72ed;
  box-shadow: 
    0 0 5px  rgba(12,114,237,0.2),
    0 0 10px  rgba(12,114,237,0.2),
    0 0 15px  rgba(12,114,237,0.2),
    0 0 20px  rgba(12,114,237,0.2);
  margin-bottom: 6px;
}

.topBox2{
  width: 65px !important; 
  height: 65px !important;
  padding: 8px;
  border-radius: 8px;
  border: 3px solid red;
  box-shadow: 
    0 0 5px  rgba(213,5,5,0.3),
    0 0 10px  rgba(213,5,5,0.3),
    0 0 15px  rgba(213,5,5,0.3),
    0 0 20px  rgba(213,5,5,0.3);
  margin-bottom: 6px;
}

#bottom{
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
}
#bottom i{
  width: 40px;
  height: 40px;
  border-radius: 8px;
  outline: 3px solid #0c72ed;
  box-shadow: 
    0 0 5px  rgba(12,114,237,0.5),
    0 0 10px  rgba(12,114,237,0.5),
    0 0 15px  rgba(12,114,237,0.5),
    0 0 20px  rgba(12,114,237,0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

}


/* alert Message */

#alertMsg{
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
#alertBox{
  width: 80%;
  aspect-ratio: 1/1;
  background: radial-gradient(circle at center, #00145b, #000825);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  outline: 3px solid #0c72ed;
  box-shadow: 
    0 0 5px  rgba(12,114,237,0.5),
    0 0 10px  rgba(12,114,237,0.5),
    0 0 15px  rgba(12,114,237,0.5),
    0 0 20px  rgba(12,114,237,0.5);
  color: white;
}
#alertMsgTitle{
  font-size: 30px;
  font-weight: 600;
}
#alertMsg i{
  font-size: 100px;
  margin: 10px;
}
#alertMsgMessage{
  font-size: 13px;
  color: gray !important;
  font-weight: 500;
}
#alertMsg button{
  width: 70%;
  height: 35px;
  margin: 10px;
  border: none;
  background-color: gold;
  color: white !important;
  font-weight: 550;
  border-radius: 50px;
}


#startingSplash{
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
#startingSplash div{
  width: 100%;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
#startingSplash p{
  font-size: 22px;
  text-decoration: italic;
  font-weight: 500;
  text-align: center;
  color: white;
}
#menu{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: flex-end;
}
#menuBox{
  width: 100%;
  background-color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  flex-direction: column;
  padding: 6px;
}
#menuHeader{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}
#menuHeader div{
  width: 60px;
  height: 6px;
  background-color: darkgray;
  border-radius: 20px;
}
.menuColumns{
  width: 100%;
  height: 80px;
  padding: 15px;
  display: flex;
}
.menuProfilePictures{
  width: 55px;
  height: 55px;
  padding: 2px;
  outline: 2.4px solid #f40057;
  border-radius: 50%;
  display: flex;
}
.menuLinears{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  flex-grow: 1;
}
.menuLinears h1{
  font-size: 15px;
}
.menuLinears p{
  font-size: 12px;
}
.rankDiv{
  display: flex;
  flex-direction: column;
  font-size: 10px;
  justify-content: center;
  align-items: center;
}
.rankDiv div{
  display: flex;
}
.rankImg{
  width: 40px;
  height: 40px;
}
#btnsDiv{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}
#menu button{
  width: 48%;
  height: 40px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 15px;
}
#btn1{
  min-width: 90px;
  position: relative;
  background: linear-gradient(to bottom, #f4005730, #e2009f4d);
  color: #f40057;
  /* border: 1px solid #7f0059a8; */
  padding: 6px;
  border-radius: 8px; 
  font-size: 16px;
  box-shadow: 
  inset 0px -2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
#btn2 {
  min-width: 90px;
  position: relative;
  background: linear-gradient(to bottom, #d62976, #f40057);
  color: #ffffff;
  /* border: 1px solid #7f0059a8; */
  padding: 6px;
  border-radius: 8px; 
  font-size: 16px;
  box-shadow: 
  inset 0px -2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
/* Glossy Reflection Effect */
#btnsDiv button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-50%) rotate(-3deg);
}