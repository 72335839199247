#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#logo{
  width: 65px;
  height: 65px;
  margin-bottom: 55px;
  opacity: 0.9;
}
#body{
  width: 100%;
  height: auto;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loadingDiv{
  width: 100%;
  text-align: center;
  padding: 30px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
}
#loadingDiv p{
  margin-left: 8px;
  color: #f40057;
  font-weight: 400;
}
#loader{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1.5px solid #f40057;
  border-top: 1.5px solid transparent;
  animation: 1s loaderAnimation linear infinite;
}
@keyframes loaderAnimation{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}