#bg{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#header{
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #352e2e, #181515);
  color: white;
}
#headerLinear1{
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}
#wallet{
  padding: 6px;
  background-color: white;
  border-radius: 5px;
  color: black;
  font-size: 15px;
  font-weight: 500;
  height: auto;
  overflow: visible;
  min-width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
#headerLinear2{
  width: 100%;
  height: 20px;
  background: linear-gradient(180deg, red, #7d0707);
  display: flex;
}

#body{
  width: 100%;
  flex-grow: 1;
  background-image: url('../../assets/greenTextureBg.jpeg');
  background-size: contain;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.blocks{
  flex-grow: 1;
  margin: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.8);
  border-radius: 7px;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: white;
  padding: 8px;
  border: 2px solid;
}
.blocks p{
  width: 50%;
  text-align: center;
}
.cardsDiv{
  width: auto;
  height: auto;
  display: flex;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  border-radius: 10px;
}
.cardsDiv p{
  font-size: 14px;
  padding: 6px;
}
.cards{
  width: auto;
  height: 150px;
}
#footer{
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #181515, #352e2e);
  color: white;
}
#footerLinear1{
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
#footerLinear2{
  width: 100%;
  height: 20px;
  background: linear-gradient(180deg, #7d0707, red);
  overflow: hidden;
}
#moneyControlBtns{
  display: flex;
  align-items: center;
  height: 100%;
}
#increseBtn{
  width: 35px;
  height: 35px;
  border: none;
  margin: 6px;
  color: white;
  background-color: green;
  font-size: 16px;
  font-weight: 500;
}
#moneyDisplay{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: darkcyan;
  color: white;
  font-weight: 500;
}
#decreseBtn{
  width: 35px;
  height: 35px;
  border: none;
  margin: 6px;
  color: white;
  background-color: red;
  font-size: 25px;
  font-weight: 500;
}
#playBtn{
  width: 65px;
  height: 35px;
  border: none;
  background-color: darkcyan;
  color: white;
  border-radius: 4px;
}